import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'
import { ImUserTie } from 'react-icons/im'

import { useAuth } from 'shared/contexts/AuthProvider'
// import logo from 'assets/logo.png'
import { GiSpottedBug } from 'react-icons/gi'

const menuItems = {
  main: [
    { caption: 'Κεντρική', to: '/' },
    { caption: 'Ποιοί Είμαστε', to: '/about-us' },
    { caption: 'Πώς δουλεύει', to: '/our-mission' },
    { caption: 'Επικοινωνία', to: '/contact-us' }
  ],
  pofile: [
    { caption: 'Παραγγελίες', to: '/orders' },
    { caption: 'Αποσύνδεση', to: '/logout' }
  ]
}

const MobileMenu = ({ handleToggleMoblieMenu, isActive }: any) => {
  const auth = useAuth()
  const mobileItemStyle = (to: string): string =>
    `hover:bg-gray-700 hover:text-white block ' ${
      isActive(to) ? 'text-indigo-600' : 'text-gray-300'
    } px-3 py-2 rounded-md text-base font-medium`

  return (
    <div className="lg:hidden">
      <div className="px-2 pt-2 pb-3 space-y-1">
        {menuItems.main.map((item, index) => {
          return (
            <Link
              to={item.to}
              className={mobileItemStyle(item.to)}
              key={index}
              onClick={handleToggleMoblieMenu}>
              {item.caption}
            </Link>
          )
        })}
        <div className="pt-4 pb-3 border-t border-gray-700 flex items-center px-5 mb-3">
          <div className="flex-shrink-0">
            <ImUserTie
              className={'h-10 w-10 rounded-full  bg-white text-black p-1'}
            />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium leading-none text-white">
              {auth.user?.fullName}
            </div>
            <div className="text-sm font-medium leading-none text-gray-400">
              {auth.user?.email}
            </div>
          </div>
        </div>
        {menuItems.pofile.map((item, index) => {
          return (
            <Link
              to={item.to}
              className={mobileItemStyle(item.to)}
              key={index}
              onClick={handleToggleMoblieMenu}>
              {item.caption}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const AuthorizedUserMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const handleToggleMoblieMenu = () => setShowMobileMenu((state) => !state)
  const [showDropdown, setShowDropdown] = useState(false)
  const auth = useAuth()
  const location = useLocation()
  const isActive = (to: string) => location.pathname === to
  const dropdownItemsStyle = (to: string): string =>
    `block px-4 py-2 text-sm hover:bg-gray-100 ${
      isActive(to) ? 'text-indigo-700' : 'text-gray-700'
    }`
  const desktopItemStyle = (to: string): string =>
    `border-2 border-indigo-600 hover:bg-indigo-600 text-white ${
      isActive(to) ? 'bg-indigo-600' : 'bg-transparent'
    } px-3 py-2  text-sm font-medium rounded-full`
  const toggleDropdown = () => setShowDropdown((state) => !state)
  return (
    <nav className="bg-black">
      <div className="max-w mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              type="button"
              onClick={() => setShowMobileMenu((state) => !state)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <HiMenu />
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
            <div className="select-none flex-shrink-0 flex items-center">
              {/* <img className="block h-11 w-auto" src={logo} alt="Logo" /> */}
              <GiSpottedBug className="block h-10 w-auto text-red-500" />
            </div>
            <div className="hidden lg:block lg:ml-6">
              <div className="flex space-x-4">
                {menuItems.main.map((item, index) => {
                  return (
                    <Link
                      to={item.to}
                      className={desktopItemStyle(item.to)}
                      key={index}>
                      {item.caption}
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="hidden lg:flex items-center justify-end  lg:w-0">
            <div className="ml-3 relative">
              <div className="flex flex-row  items-center ">
                <div className="mr-2 text-white">{auth.user?.email}</div>
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <ImUserTie className="h-10 w-10 rounded-full bg-white text-black p-1" />
                </button>
              </div>
              {showDropdown && (
                <div className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {menuItems.pofile.map((item, index) => {
                    return (
                      <Link
                        to={item.to}
                        onClick={() => setShowDropdown(false)}
                        className={dropdownItemsStyle(item.to)}
                        key={index}>
                        {item.caption}
                      </Link>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showMobileMenu && (
        <MobileMenu
          handleToggleMoblieMenu={handleToggleMoblieMenu}
          isActive={isActive}
        />
      )}
    </nav>
  )
}

export default AuthorizedUserMenu
