import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const Login = lazy(() => import('shared/pages/auth/login/Login'))
const Register = lazy(() => import('shared/pages/auth/register/Register'))
const ForgotPassword = lazy(
  () => import('shared/pages/auth/forgotPassword/ForgotPassword')
)
const ResetPassword = lazy(
  () => import('shared/pages/auth/resetPassword/ResetPassword')
)
const Logout = lazy(() => import('shared/pages/auth/Logout'))
const NotFound = lazy(() => import('shared/layout/NotFound'))
const Requests = lazy(() => import('admin/pages/requests/Requests'))
const Users = lazy(() => import('admin/pages/users'))
const Causes = lazy(() => import('admin/pages/causes/Causes'))
const Home = lazy(() => import('admin/pages/home/Home'))

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:resetLink" element={<ResetPassword />} />
      <Route path="logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
      <Route path="requests" element={<Requests />} />
      <Route path="causes" element={<Causes />} />
      <Route path="users" element={<Users />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AdminRoutes
