import { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { useAuth } from 'shared/contexts/AuthProvider'

type LayoutContextProps = {
  view: string
  screen: string
  auth: boolean
}
const TABLET_BREAKPOINT = 1000
const MOBILE_BREAKPOINT = 767

export const LayoutContext = createContext<LayoutContextProps>({
  view: 'user',
  screen: 'web',
  auth: false
})

export const LayoutProvider = ({ children }: any) => {
  const auth = useAuth()
  const [layout, setLayout] = useState({
    view: 'user',
    screen:
      window.innerWidth <= MOBILE_BREAKPOINT
        ? 'mobile'
        : window.innerWidth <= TABLET_BREAKPOINT
        ? 'tablet'
        : 'web',
    auth: auth.isAuthenticated()
  })

  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth <= MOBILE_BREAKPOINT &&
        layout.screen !== 'mobile'
      ) {
        setLayout((prevLayout) => ({ ...prevLayout, screen: 'mobile' }))
      }
      if (window.innerWidth > TABLET_BREAKPOINT && layout.screen !== 'web') {
        setLayout((prevLayout) => ({ ...prevLayout, screen: 'web' }))
      }
      if (
        window.innerWidth <= TABLET_BREAKPOINT &&
        window.innerWidth >= MOBILE_BREAKPOINT &&
        layout.screen !== 'tablet'
      ) {
        setLayout((prevLayout) => ({ ...prevLayout, screen: 'tablet' }))
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [layout])

  useEffect(() => {
    const view = auth.isAdmin ? 'admin' : 'user'
    setLayout((prevLayout) => ({
      ...prevLayout,
      view: view,
      auth: auth.isAuthenticated()
    }))
  }, [auth])

  const values = useMemo(() => layout, [layout])

  return (
    <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>
  )
}

export const useLayout = () => useContext(LayoutContext)
