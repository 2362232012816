import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

const Login = lazy(() => import('shared/pages/auth/login/Login'))
const Register = lazy(() => import('shared/pages/auth/register/Register'))
const ForgotPassword = lazy(
  () => import('shared/pages/auth/forgotPassword/ForgotPassword')
)
const ResetPassword = lazy(
  () => import('shared/pages/auth/resetPassword/ResetPassword')
)
const VerifyEmail = lazy(
  () => import('user/pages/auth/VerifyEmail/VerifyEmail')
)
const Logout = lazy(() => import('shared/pages/auth/Logout'))
const Home = lazy(() => import('user/pages/home/Home'))
const ProcessingPayment = lazy(
  () => import('user/pages/ebanking/processing/ProcessingPayment')
)

const CompletePayment = lazy(
  () => import('user/pages/ebanking/complete/CompletePayment')
)

const Request = lazy(() => import('user/pages/request/index'))
const Orders = lazy(() => import('user/pages/orders/Orders'))
const AboutUs = lazy(() => import('user/pages/aboutUs/AboutUs'))
const OurMission = lazy(() => import('user/pages/ourMission/OurMission'))
const Contact = lazy(() => import('user/pages/contact/Contact'))
const PrivacyPolicy = lazy(
  () => import('user/pages/privacyPolicy/PrivacyPolicy')
)
const NotFound = lazy(() => import('shared/layout/NotFound'))

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:resetLink" element={<ResetPassword />} />
      <Route path="verify-email/:verifyToken" element={<VerifyEmail />} />
      <Route path="logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
      <Route path="/request/*" element={<Request />} />
      <Route
        path="ebanking/payment/processing/:id"
        element={<ProcessingPayment />}
      />
      <Route
        path="ebanking/payment/completed/:id"
        element={<CompletePayment />}
      />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/our-mission" element={<OurMission />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default UserRoutes
