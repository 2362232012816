const Footer = () => {
  const thisYear = new Date().getFullYear()
  return (
    <footer className="bg-black">
      <div className="flex flex-col items-center">
        <div className="sm:w-2/3 text-center p-3">
          <p className="text-sm text-white font-bold mb-2 select-none">
            pasxalitsa {thisYear} © All rights reserved.Designed by{' '}
            <a href="https://analyseis.gr" className="text-indigo-500">
              Analyseis
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
