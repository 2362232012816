import { useEffect, useState } from 'react'
import UserRoutes from 'user/layout/UserRoutes'
import UnAuthorizedMenu from 'shared/layout/menu/UnAuthorizedMenu'
import Footer from 'shared/layout/Footer'
import { useLayout } from 'shared/contexts/LayoutProvider'
import { useAuth } from 'shared/contexts/AuthProvider'
import AdminMenu from 'admin/layout/AdminMenu'
import AuthorizedUserMenu from 'user/layout/userMenu/AuthorizedUserMenu'
import AdminRoutes from 'admin/layout/AdminRoutes'
import styles from './layout.module.css'
import Seo from '../components/Seo/Seo'

const Layout = () => {
  const [Layout, setLayout] = useState<JSX.Element>(<div />)
  const [Routes, setRoutes] = useState<JSX.Element>(<div />)
  const layout = useLayout()
  const auth = useAuth()
  useEffect(() => {
    const LayoutSelector = () => {
      if (auth.user?.roles === 'admin') {
        return layout.auth ? <AdminMenu /> : <UnAuthorizedMenu />
      }
      return layout.auth ? <AuthorizedUserMenu /> : <UnAuthorizedMenu />
    }
    const RoutesSelector = () => {
      if (auth.user?.roles === 'admin') {
        return <AdminRoutes />
      }
      return <UserRoutes />
    }
    setRoutes(RoutesSelector)
    setLayout(LayoutSelector)
  }, [layout, auth])

  return (
    <div
      className={`flex flex-col h-screen justify-between ${
        layout.view === 'user' && styles.background
      }`}>
      <Seo
        title="pasxalitsa"
        description='
        Γνωρίστε τα βλαβερά έντομα, μύκητες και ζιζάνια της παραγωγής σας.
        Για όλες τις καλλιέργειες της Ελλάδος!
        Και λάβετε στο e-mail σας όλο το "οπλοστάσιο" της φυτοπροστασίας 
        με το εμπορικό του όνομα.'
        keywords={[
          'έντομα',
          'μύκητες',
          'ζιζάνια',
          'καλλιέργειες',
          'φυτοπροστατευτικά προϊόντα',
          'πασχαλίτσα',
          'αγρότες',
          'γεωπόνος',
          'αμπέλι'
        ]}
      />
      <header>{Layout}</header>
      <main className="mb-auto overflow-auto">{Routes}</main>
      <Footer />
    </div>
  )
}

export default Layout
