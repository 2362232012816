import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import jwt_decode from 'jwt-decode'

import { IUserAuthProfile } from 'shared/models/IUser'

type AuthContextProps = {
  logout: () => void
  login: (jwt: string) => void
  isAuthenticated: () => boolean
  isAdmin: boolean
  user: IUserAuthProfile | null
}

const AuthContext = createContext<AuthContextProps>({
  logout: () => {},
  login: () => {},
  isAuthenticated: () => false,
  isAdmin: false,
  user: null
})

type IAuthProviderProps = {
  children: any
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<IUserAuthProfile | null>(null)
  //hint can use useMemo localstorage.getItem and check if exist
  const [isAdmin, setIsAdmin] = useState(false)

  const fillUser = useCallback((jwt: string) => {
    const decoded: any = jwt_decode(jwt)
    const user: IUserAuthProfile = {
      fullName: decoded.fullName,
      email: decoded.email,
      roles: decoded.roles
    }
    if (user.roles === 'admin') {
      setIsAdmin(true)
    }
    setUser(user)
  }, [])

  useEffect(() => {
    const loadUser = async () => {
      try {
        const jwt = localStorage.getItem('token')
        if (jwt) fillUser(jwt)
      } catch {
        setUser(null)
      }
    }
    loadUser()
  }, [fillUser])

  const login = useCallback(
    (jwt: string) => {
      localStorage.setItem('token', jwt)
      fillUser(jwt)
    },
    [fillUser]
  )

  const logout = useCallback(async () => {
    localStorage.removeItem('token')
    setUser(null)
    setIsAdmin(false)
  }, [])

  const isAuthenticated = useCallback(() => {
    const token = localStorage.getItem('token')
    const existToken = token !== null && token !== undefined
    if (existToken) {
      const decoded: any = jwt_decode(token || '')
      return decoded.exp >= Date.now() / 1000
    }
    return false
  }, [])

  const providerValue = useMemo(
    () => ({
      logout,
      login,
      user,
      isAuthenticated,
      isAdmin
    }),
    [logout, login, user, isAuthenticated, isAdmin]
  )

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export default AuthProvider
