import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'
import { ImUserTie } from 'react-icons/im'
import { GiSpottedBug } from 'react-icons/gi'

import { useAuth } from 'shared/contexts/AuthProvider'

const menuItems = {
  main: [
    { caption: 'Κεντρική', to: '/' },
    { caption: 'Χρήστες', to: '/users' },
    { caption: 'Παραγγελίες', to: '/requests' },
    { caption: 'Αίτια', to: '/causes' }
  ],
  pofile: [{ caption: 'Αποσύνδεση', to: '/logout' }]
}

const MobileMenu = ({ handleToggleMoblieMenu, isActive }: any) => {
  const auth = useAuth()
  const mobileItemStyle = (to: string): string =>
    `hover:text-blue-500 block ' ${
      isActive(to) ? 'text-blue-600' : 'text-blue-900'
    } px-3 py-2 rounded-md text-base font-medium`
  return (
    <div className="sm:hidden">
      <div className="px-2 pt-2 pb-3 space-y-1">
        {menuItems.main.map((item, index) => {
          return (
            <Link
              to={item.to}
              className={mobileItemStyle(item.to)}
              key={index}
              onClick={handleToggleMoblieMenu}>
              {item.caption}
            </Link>
          )
        })}
        <div className="pt-4 pb-3 border-t border-gray-700 flex items-center px-5 mb-3">
          <div className="flex-shrink-0">
            <ImUserTie
              className={'h-10 w-10 rounded-full bg-gray-600 text-white p-1'}
            />
          </div>
          <div className="ml-3">
            <div className="text-base font-medium leading-none text-black">
              {auth.user?.fullName}
            </div>
            <div className="text-sm font-medium leading-none text-gray-400">
              {auth.user?.email}
            </div>
          </div>
        </div>
        {menuItems.pofile.map((item, index) => {
          return (
            <Link
              to={item.to}
              className={mobileItemStyle(item.to)}
              key={index}
              onClick={handleToggleMoblieMenu}>
              {item.caption}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const AdminMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const handleToggleMoblieMenu = () => setShowMobileMenu((state) => !state)
  const [showDropdown, setShowDropdown] = useState(false)
  const auth = useAuth()
  const location = useLocation()
  const isActive = (to: string) => location.pathname === to
  const desktopItemStyle = (to: string): string =>
    `block md:inline-block  hover:text-blue-500 ${
      isActive(to) ? 'text-blue-600' : 'text-blue-900'
    } px-3 py-3 border-b-2 border-blue-900 md:border-none`
  const dropdownItemsStyle =
    'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'

  const toggleDropdown = () => setShowDropdown((state) => !state)
  return (
    <nav className="bg-blue-200">
      <div className="max-w mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              onClick={() => setShowMobileMenu((state) => !state)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <HiMenu />
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="select-none flex-shrink-0 flex items-center">
              {/* <img className="block h-11 w-auto" src={logo} alt="Logo" /> */}
              <GiSpottedBug className="block h-10 w-auto text-red-500" />
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {menuItems.main.map((item, index) => {
                  return (
                    <Link
                      to={item.to}
                      className={desktopItemStyle(item.to)}
                      key={index}>
                      {item.caption}
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>

          <div className="hidden sm:flex items-center justify-end md:flex-1 lg:w-0">
            <div className="ml-3 relative">
              <div className="flex flex-row  items-center ">
                <div className="mr-2 text-black">{auth.user?.email}</div>
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <ImUserTie className="h-10 w-10 rounded-full bg-white text-black p-1" />
                </button>
              </div>
              {showDropdown && (
                <div className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {menuItems.pofile.map((item, index) => {
                    return (
                      <Link
                        to={item.to}
                        onClick={() => setShowDropdown(false)}
                        className={dropdownItemsStyle}
                        key={index}>
                        {item.caption}
                      </Link>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showMobileMenu && (
        <MobileMenu
          handleToggleMoblieMenu={handleToggleMoblieMenu}
          isActive={isActive}
        />
      )}
    </nav>
  )
}

export default AdminMenu
