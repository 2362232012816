import { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import { QueryClientProvider } from 'react-query'

import Layout from 'shared/layout/Layout'
import Loading from 'shared/layout/Loading'
import { AuthProvider } from 'shared/contexts/AuthProvider'
import { LayoutProvider } from 'shared/contexts/LayoutProvider'
import { queryClient } from 'shared/lib/queryClient'
import useGaTracker from 'shared/utils/useGaTracker'
import useDisableConsoleApi from 'shared/utils/useDisableConsoleApi'

function App() {
  const contextClass: any = {
    success: 'bg-green-300',
    error: 'bg-red-300',
    info: 'bg-gray-300',
    warning: 'bg-orange-400',
    default: 'bg-indigo-300',
    dark: 'bg-white-300 font-gray-300'
  }

  const { handleDisableConsoleApi } = useDisableConsoleApi()
  useGaTracker()
  handleDisableConsoleApi()

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading />}>
        <ToastContainer
          toastClassName={({ type }: any) =>
            contextClass[type || 'default'] +
            ' relative flex p-1 min-h-10 rounded-md' +
            ' justify-between overflow-hidden cursor-pointer'
          }
          bodyClassName={() => 'text-sm font-white font-med block p-3'}
          autoClose={4000}
          position="top-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AuthProvider>
          <LayoutProvider>
            <Layout />
          </LayoutProvider>
        </AuthProvider>
      </Suspense>
    </QueryClientProvider>
  )
}

export default App
