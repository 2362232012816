import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const useGaTracker = () => {
  const location = useLocation()

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('G-PGW09Z87F1')
    }
  }, [])

  useEffect(() => {
    ReactGA.send(location.pathname + location.search)
  }, [location])
}

export default useGaTracker
