import { Helmet } from 'react-helmet'

type IProps = {
  title: string
  description: string
  pathSlug?: string
  keywords: string[]
}
const Seo = ({ title, description, pathSlug = '', keywords }: IProps) => {
  const url = `https://pasxalitsa.kladoselaias.gr/${pathSlug}` //TODO   `https://e-pasxalitsa.gr/${pathSlug}`
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          name: 'keywords',
          content: keywords.join(',')
        }
      ]}
      link={[
        {
          rel: 'canonical',
          href: url
        }
      ]}
    />
  )
}
export default Seo
